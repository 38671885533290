import React from "react";
import styles from "./Picser.module.css";
const Picser = ({ imager }) => {
  return (
    <div className={styles.fulp}>
      <img src={require("./../../images/" + imager)} />
    </div>
  );
};

export default Picser;
