import { useEffect, useState } from "react";
import logo from "./logo.svg";
import { Link } from "react-router-dom";
import "./App.css";
import Header from "./components/header/Header";
import BigBack from "./components/BigBack/BigBack.module";
import Card from "./components/Card/Card.module";
import Vids from "./components/Vids/Vids.module";
import HowBuy from "./components/HowBuy/HowBuy.module";
import Topsente from "./components/TopSente/Topsente.module";
import BuyCta from "./components/BuyCta/BuyCta.module";
import Picser from "./components/Picser/Picser.module";

function App() {
  useEffect(() => {
    const videos = document.querySelectorAll("video");

    // Pause all <video> elements except for the one that started playing.
    function pauseOtherVideos({ target }) {
      for (const video of videos) {
        if (video !== target) {
          video.pause();
        }
      }
    }

    // Listen for the 'play' event on all the <video> elements.
    for (const video of videos) {
      video.addEventListener("play", pauseOtherVideos);
    }
  });
  return (
    <>
      <Topsente />
      <BigBack />
      <div className="app-container">
        <div style={{ marginTop: "30px" }}>
          <BuyCta
            target="_blank"
            downloadmode="true"
            location="DAPAPER.pdf"
            title="DAPAPER"
            hover="Tap! to download"
            headmover="false"
          />
        </div>

        <BuyCta
          target="false"
          downloadmode="false"
          title="Buy"
          hover="Buy Roneld"
          headmover="true"
          location="https://dexscreener.com/solana/4q7cpbsqrdbrtufdcvjhuz11alpbycfyxztzi4yhfewf"
        />

        <Picser imager="chrissport.png" />
        <Picser imager="chrisbuy.jpg" />
        <Picser imager="chrishow.jpg" />
        <Picser imager="chrisbottom.png" />

        <Card />
        <HowBuy />

        <div className="copyright">
          $RONELD has got nothing to do with Cristiano Ronaldo!
          <br />© 2024 by Crist Roneld $RONELD. All rights reserved.
        </div>
      </div>
    </>
  );
}

export default App;
