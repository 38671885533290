import React from "react";
import styles from "./Header.module.css";
import chris from "./../../images/chrismouthop.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  return (
    <header>
      <div className={`app-container ${styles.header}`}>
        <div className={styles.lefticon}>
          <img src={chris} />
          $RONELD
        </div>
        <div className={styles.rightbox}>
          <a href="https://dexscreener.com/solana/4q7cpbsqrdbrtufdcvjhuz11alpbycfyxztzi4yhfewf">
            <div className={styles.buynow}>
              Buy Now
              <FontAwesomeIcon icon={faMoneyBillTransfer} />
            </div>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
