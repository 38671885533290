import React from "react";
import styles from "./Vids.module.css";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import chriscup from "./../../images/chriscup.jpeg";
import chrisfoot from "./../../images/chrisfoot.jpeg";

const jinvid = [chriscup, chrisfoot];

const Vids = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div
        style={{
          //   backgroundColor: "#ddd",
          borderRadius: "10px",
          marginBottom: "-80px",
          //   padding: "10px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className={styles.jinth}
        style={{
          width: "80px",
          //   color: "blue",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          overflow: "hidden",
          boxShadow: "black 0px 0px 8px -5px",
        }}
      >
        <img src={jinvid[i]} />
      </div>
    ),
  };
  return (
    <div className={styles.videos}>
      <Slider {...settings}>
        <div className={styles.eachvideo}>
          <img src={chriscup} />
        </div>
        <div className={styles.eachvideo}>
          <img src={chrisfoot} />
        </div>
      </Slider>
    </div>
  );
};

export default Vids;
