import React from "react";
import styles from "./HowBuy.module.css";
const HowBuy = () => {
  return (
    <div className={styles.howBuy}>
      <div className={styles.bigtitle}>How To Buy $RONELD</div>
      <div className={styles.dcsc}>
        <h2>Step 1: CREATE A WALLET</h2>
        <p>
          Download Phantom or your wallet of choice from the app store or Google
          Play for free. Desktop users, download the Google Chrome extension by
          going to Phantom. Get Some SOL Have SOL in your wallet to switch to
          $RONELD. If you don’t have any SOL, you can buy directly on Phantom,
          transfer from another wallet, or buy on another exchange and send it
          to your wallet.
        </p>
        <h2>Step 2: Go to Raydium</h2>
        <p>
          Connect to Raydium. Go to Raydium in google chrome or on the browser
          inside your Phantom app. Connect your wallet. Paste the $RONELD token
          address into Raydium, select $RONELD, and confirm. When Phantom
          prompts you for a wallet signature, sign.
        </p>
        <h2>Step 3: $RONELD</h2>
        <p>
          Switch SOL for $RONELD. We have Zero taxes so you don’t need to worry
          about buying with a specific slippage, although you may
        </p>
      </div>
    </div>
  );
};

export default HowBuy;
