import React from "react";
import styles from "./Card.module.css";
import chris from "./../../images/chrismouthop.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTelegram, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faChartArea, faSquare } from "@fortawesome/free-solid-svg-icons";
import Eye from "../Eye/Eye.module";
const Card = () => {
  return (
    <div className={styles.card}>
      {/* <div className={styles.toppic}>
        <img src={jinpeng} />
      </div> */}

      <div className={styles.containbtns}>
        <a href="https://t.me/CristRoneld">
          <div className={styles.brandcl}>
            <FontAwesomeIcon icon={faTelegram} />
            Telegram
          </div>
        </a>
        <a href="https://x.com/RoneldSol">
          <div className={styles.brandcl}>
            <FontAwesomeIcon icon={faXTwitter} />
            Twitter
          </div>
        </a>
        <a href="https://www.dextools.io/app/en/solana/pair-explorer/4q7cpbsqRdbrtufdcVJhUZ11aLpbyCFyxztZi4yHFEWF?t=1717452961987">
          <div className={styles.brandcl}>
            <FontAwesomeIcon icon={faSquare} />
            Dex Tools
          </div>
        </a>
        <a href="https://dexscreener.com/solana/4q7cpbsqrdbrtufdcvjhuz11alpbycfyxztzi4yhfewf">
          <div className={styles.brandcl}>
            <FontAwesomeIcon icon={faChartArea} />
            Dexscreener
          </div>
        </a>
      </div>
    </div>
  );
};

export default Card;
