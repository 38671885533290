import React, { useState, useRef, useEffect } from "react";
import styles from "./BuyCta.module.css";
import ronald from "./../../images/ronaldoicon.png";
import { Link } from "react-router-dom";
const BuyCta = ({
  title,
  hover,
  headmover,
  location,
  downloadmode,
  target,
}) => {
  const [hover2, setHover2] = useState(false);
  const enter = useRef(null);
  const out = useRef(null);
  const widther = useRef(null);
  const imager = useRef(null);
  useEffect(() => {
    if (hover2) {
      if (headmover == "true") {
        imager.current.style.width = "100px";
        imager.current.style.height = "auto";
        imager.current.style.marginTop = "130px";
      }

      out.current.style.opacity = 0;
      out.current.style.display = "none";
      setTimeout(function () {
        if (
          out.current.style.display == "none" &&
          widther.current.style.width == "300px"
        ) {
          enter.current.style.display = "block";

          enter.current.style.opacity = 1;
        }
      }, 200);

      widther.current.style.width = "300px";
      widther.current.style.height = "60px";
    } else {
      if (headmover == "true") {
        imager.current.style.marginTop = "80px";
        imager.current.style.width = "100px";
        imager.current.style.height = "0px";
      }

      enter.current.style.opacity = 0;
      enter.current.style.display = "none";
      setTimeout(function () {
        if (enter.current.style.display == "none") {
          out.current.style.display = "block";

          out.current.style.opacity = 1;
        }
      }, 200);
      widther.current.style.width = "170px";

      widther.current.style.height = "50px";
    }
  }, [hover2]);
  return (
    <div className={styles.buypart}>
      {downloadmode !== "false" ? (
        <Link to={location} download target={target}>
          {" "}
          <div
            ref={widther}
            className={styles.buyercta}
            onMouseOver={() => setHover2(true)}
            onMouseLeave={() => setHover2(false)}
          >
            <div ref={out}>{title}</div>
            <div ref={enter}>{hover}</div>
          </div>
          {headmover == "true" ? (
            <img ref={imager} src={ronald} className={styles.imager} />
          ) : (
            ""
          )}
        </Link>
      ) : (
        <Link to={location}>
          <div
            ref={widther}
            className={styles.buyercta}
            onMouseOver={() => setHover2(true)}
            onMouseLeave={() => setHover2(false)}
          >
            <div ref={out}>{title}</div>
            <div ref={enter}>{hover}</div>
          </div>
          {headmover == "true" ? (
            <img ref={imager} src={ronald} className={styles.imager} />
          ) : (
            ""
          )}
        </Link>
      )}
    </div>
  );
};

export default BuyCta;
