import React from "react";
import styles from "./Topsente.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
const Topsente = () => {
  return (
    <div className={styles.fullsente}>
      <div className={styles.descs}>
        Suuiii! Impossible iz nuthin with $RONELD!
        <br></br> Kick off da football revolution on Solana. Score big with us!
      </div>
      <div className={styles.capart}>
        CA: Hs1RexWAYKk7g7x5qRkHhopbAhyVgeEvJG5HhiT6bmpg
        <CopyToClipboard text="Hs1RexWAYKk7g7x5qRkHhopbAhyVgeEvJG5HhiT6bmpg">
          <FontAwesomeIcon title="Copy To Clipbpard" icon={faCopy} />
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default Topsente;
