import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./BigBack.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import chris from "./../../images/ronal01.png";

const BigBack = () => {
  const vid = useRef(null);
  const diver = useRef(null);
  const [pl, setPl] = useState(false);
  const setSize = () => {
    if (!vid.current) return;
    let player = vid.current.getBoundingClientRect();
    diver.current.style.height = player.height + "px";
  };
  const [vidplay, setVidplay] = useState(false);
  const handlePlay = () => {
    if (vidplay === false) {
      setVidplay(true);
      vid.current.play();
    } else {
      setVidplay(false);
      vid.current.pause();
    }
  };
  // useEffect(() => {
  //   setSize();

  //   vid.current.addEventListener("pause", function () {
  //     setVidplay(false);
  //   });
  // }, []);
  // useLayoutEffect(() => {
  //   window.addEventListener("resize", setSize);
  // });

  return (
    // <div className={styles.bigback}>
    //   <div className={styles.insidebig}>
    //     <div className={`app-container ${styles.twocols}`}>
    //       {/* <div className={styles.twocols}> */}
    //       <div className={styles.colone}>
    //         <div className={styles.insidecolone}>
    //           <h1>Shi Jin Peng</h1>
    //           <p>
    //             Her is my koin - $JIN, de kryptocurenci for Chaina we ken beliv
    //             in. YES, WI KEN! chenge financ, help ppl invest in de futur.
    //             This is HOP for memekoin spac you hev been weit for.
    //           </p>
    //           <div className={styles.cta}>Let's Go</div>
    //         </div>
    //       </div>
    //       <div className={styles.coltwo}>
    //         <img className={styles.imageright} src={jin5} />
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className={styles.bigback}>
      {/* <video width="100%" ref={vid}>
        <source src={jinplane} type="video/mp4" />
      </video> */}
      <img src={chris} className={styles.bigimg} />
      {/* <div className={styles.playbtn} ref={diver} onClick={() => handlePlay()}>
        {vidplay === true ? (
          ""
        ) : (
          <div className={styles.littlePlayIcon}>
            <FontAwesomeIcon icon={faPlay} />
          </div>
        )}
      </div> */}
    </div>
  );
};

export default BigBack;
